import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as blogStyles from './blog.module.scss'
import { renderRichText } from "gatsby-source-contentful/rich-text"

export const query = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishedDate(fromNow: true)
      post {
        raw
      }
      linkImage {
        gatsbyImageData
        title
      }
    }
  }
`

const Blog = ({ data: { contentfulBlogPost: { title, publishedDate, post, linkImage } } }) => {
  const options = {
    renderNode: {
      "embedded-asset-block": () => {
        const image = getImage(linkImage.gatsbyImageData)
        return <GatsbyImage className={blogStyles.blogImage} alt={linkImage.title} image={image}/>
      }
    }
  }

  return <Layout className={blogStyles.blogLayout}>
      <div className={blogStyles.blogLayout}>
        <h1>{title}</h1>
        <p>{publishedDate}</p>
        {post && renderRichText(post, options)}
      </div>
    </Layout>
}


export default Blog
